<template>
  <div class="box">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="box-car content-main">
        <div class="box-car-top">
          <el-select
                  v-model="status"
                  placeholder="设备状态"
                  clearable
                  filterable
                  @change="$refs.tree.filter(status)">
            <el-option
                    v-for="(item, idx) in $enums.EquipmentStatus"
                    :key="idx"
                    :label="item"
                    :value="item">
            </el-option>
          </el-select>
          <el-input v-model="queryContent" placeholder="车牌/设备号" clearable></el-input>
          <el-button type="primary" @click="$refs.tree.filter(queryContent)">查询</el-button>
        </div>
        <div class="box-car-tree">
          <el-tree :data="carList"
                   node-key="id"
                   :props="defaultProps"
                   show-checkbox
                   :accordion="false"
                   :expand-on-click-node="false"
                   @check="checkClick"
                   :filter-node-method="filterNode"
                   :default-expand-all="true"
                   ref="tree">
            <!--:render-content="renderContent"-->
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ data.children ? node.label : data.id+'('+data.deviceStatus+')' }}</span>
              <div class="slot-icon">
                <span style="display:inline-block;width: 20px">
                  <i v-if="!data.children && activeIndex!==0 && (data.deviceStatus==='设备临时运行'||data.deviceStatus==='设备运行')"
                     class="iconfont iconbofang"
                     :class="{'play-icon-active': node.data.isPlay}"
                     @click="() => clickPlay(data, node)"></i>
                  <!--play-icon-active-->
                </span>
                <span @click="() => online(node)"
                      v-if="!data.children && (data.deviceStatus==='设备临时运行'||data.deviceStatus==='设备待机') "
                      class="online">{{data.deviceStatus==='设备待机'?'上线':'下线'}}</span>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="box-right content-main">
        <div class="main-top">
          <div class="top-tabs">
            <!-- <div v-for="(item, idx) in ['实时位置监控','实时视频监控']" -->
              <div v-for="(item, idx) in ['实时监控']"
                 :key="idx"
                 class="top-tabs-item curp"
                 :class="{'item-active':activeIndex===idx}"
                 @click="clickTabs(idx)">
              {{item}}
            </div>
          </div>
          <div class="top-warning curp" @click="showTable">
            <img src="@img/baojing.gif" alt="" style="width: 20px; height: 20px;vertical-align: bottom">
            <span style="margin-left: 8px">今日AI报警({{num}})</span>
          </div>
        </div>
        <div class="main-content">
          <keep-alive>

            <real-time-location v-if="activeIndex===0" :carList="checkCarItem" :deviceID="newDeviceID"
                                @check-change="selectionAllNodes"></real-time-location>

            <real-time-video v-if="activeIndex===1" :carList="playCarList"></real-time-video>
          </keep-alive>

          <paly-back v-if="activeIndex===2" :carList="playCarList" @editVideoviewNum='editVideoviewNum'></paly-back>

        </div>
        <div class="main-table" v-show="showWarning">
          <ayl-table :table="table">
            <div slot="ctrl-before">
              <span style="color: #F2090A;font-size: 16px">今日AI报警({{num}})</span>
            </div>
            <div slot="ctrl-button" style="display: flex;justify-content: space-between">
              <!--<el-button type="primary" @click="reset">重 置</el-button>-->
              <span></span>
              <div>
                <span style="color: #0575E6" @click="jump" class="curp">查看更多报警信息
                  <i class="el-icon-d-arrow-right"></i>
                </span>
                <i class="el-icon-close curp close" style="font-size: 16px;margin-left: 21px"
                   @click="showWarning=false"></i>
              </div>
            </div>
          </ayl-table>
        </div>
      </div>
    </div>

    <!-- 处理详情 -->
    <el-dialog
            title="处理详情"
            :visible.sync="detailsDialogVisible"
            width="30%"
            top="30vh"
            center>
      <div class="detail-main">
        <span>处理人：</span>
        <span>{{basicTitleData.dealName}}</span>
      </div>
      <div class="detail-main">
        <span>处理时间：</span>
        <span>{{basicTitleData.dealTime}}</span>
      </div>
      <div class="detail-main">
        <span>处理方式：</span>
        <span>{{basicTitleData.dealType}}</span>
      </div>
      <div class="detail-main">
        <span>处理内容：</span>
        <span>{{basicTitleData.dealContent}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="detailsDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="detailsDialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>


    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
      <div class="attachment-main">
        <div class="left-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%"
                 v-if="videoIdx === 1"></video>
          <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else>
        </div>
        <div class="right-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images"
                 @click="videoItem(1)"></video>
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import RealTimeVideo from './components/real-time-video'
  import RealTimeLocation from './components/real-time-location'
  import PalyBack from './components/play-back'

  export default {
    components: {RealTimeVideo, RealTimeLocation, PalyBack},
    data () {
      const vm = this
      return {
        nav: [
          {name: '安全中心'},
          {name: '安全监控', path: '/safety-center/safety-monitoring'},
        ],
        // 设备状态
        status: '',
        // 车牌/设备号
        queryContent: '',
        // 车辆列表数据
        carList: [],
        // tree 配置
        defaultProps: {
          children: 'children',
          label: 'label',
          disabled: function (data) {
            return data.deviceStatus === '初始状态' || data.deviceStatus === '无设备'
          }
        },
        newDeviceID: null,
        // 是否单选
        isRadio: true,
        // 是否只能选择根节点
        isCheckAll: false,
        // 报警数
        num: 0,
        // 当前激活tabs
        activeIndex: 0,
        // 显示报警table
        showWarning: false,
        // table列表配置
        table: {
          tableHeight: '250px',
          api: vm.$api.ListTodayAiAlarm,
          query: {
            alarmIdentifierList: [],
            queryContent: null,
          },
          searchData: [{
            type: 'cascader',
            model: 'alarmIdentifierList',
            placeholder: '报警类型',
            option: vm.$enums.AlarmNames.list,
            // 是否扁平化数据
            flat: [],
            width: '180px',
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌/司机/所属公司'
          }],
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '车牌号',
            width: '90px',
            render (h, ctx) {
              return h('span', [
                h('span', {}, ctx.row.plateNumber)
              ])
            }
          }, {
            title: '驾驶员姓名',
            width: '90px',
            render (h, ctx) {
              return h('span', [
                h('span', {}, ctx.row.driverName)
              ])
            }
          }, {
            title: '所属公司',
            showTooltip: true,
            render (h, ctx) {
              return h('span', [
                h('span', {}, ctx.row.company)
              ])
            }
          }, {
            title: '报警类型',
            key: 'alarmIdentifier',
            showTooltip: true,
          }, {
            title: '报警等级',
            key: 'alarmType',
            showTooltip: true
          }, {
            title: '报警开始时间',
            key: 'alarmStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
            width: '110px',
          }, {
            title: '报警位置',
            key: 'location',
            showTooltip: true,
          }, {
            title: '处理状态',
            width: '90px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': ctx.row.dealStatus === '人工处理',
                  },
                  on: {
                    click: vm.goDealStatus.bind(this, ctx.row)
                  }
                }, ctx.row.dealStatus)
              ])
            }
          }, {
            title: '处理人',
            key: 'dealUserName',
          }, {
            title: '附件',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': ctx.row.attache === 1,
                  },
                  on: {
                    click: vm.goPreview.bind(this, ctx.row)
                  }
                }, ctx.row.attache === 0 ? '无附件' : '预览')
              ])
            }
          }, {
            title: '操作',
            width: '120px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看详情'),
              ])
            }
          }]
        },
        //  选择的车辆的数据
        checkCarItem: [],
        //  播放视频的车辆数据
        playCarList: [],
        //  多车视频最大个数视频窗口
        videoViewNum: 4,

        detailsDialogVisible: false,
        basicTitleData: {
          dealName: null,
          dealTime: null,
          dealType: null,
          dealContent: null,
        },

        dialogFormVisible: false,
        url: '',
        videoUrl: '',
        videoIdx: 1,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          images: [],
          poster: '', //你的封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        },
      }
    },
    watch: {
      'queryContent': function (val) {
        if (!val) this.$refs.tree.filter(val)
      }
    },
    methods: {
      editVideoviewNum (val) {
        this.videoViewNum = val
      },
      // 点击播放icon
      async clickPlay (data, node) {  
        // 接受当前本次的状态
          const status = node.data.isPlay
          // 单选只会播放一个车的视频
          if (this.isRadio) {
            // 首先全部的状态设为了false
            this.setCarItemStatus(this.carList)
            // 把当前的车辆状态重新设置一次
            if (status) {
              node.data.isPlay = true
            }
          }
          // 更改状态
          node.data.isPlay = !node.data.isPlay

          //通过勾选的节点长度判断视频窗口
          // if (this.playCarList.length > this.videoViewNum) {
          //   this.$message.error('视频个数不能超过' + this.videoViewNum + '个')
          //   //return
          // }
          //不能超过视频最大个数
          if (this.playCarList.length === this.videoViewNum) {
            this.$message.error('视频个数不能超过' + this.videoViewNum + '个')
            node.data.isPlay = false
          }
          // 调用递归函数 获取播放状态的车辆列表
          this.playCarList = this.getTreePropData(this.carList)
        // let playingFlag = await this.$api.commonService_getVideoPlayingFlag({
        //   deviceName: data.deviceName
        // })
        // if(playingFlag.playAble === false) {
        //   this.$message({
        //     message: `${playingFlag.userName}用户正在观看当前设备的视频，请稍后再试。`,
        //     type: 'warning'
        //   });
        //   return
        // } else {
        //   await this.$api.commonService_setVideoPlayingFlag({
        //     deviceName: data.deviceName
        //   });
        //   // 接受当前本次的状态
        //   const status = node.data.isPlay
        //   // 单选只会播放一个车的视频
        //   if (this.isRadio) {
        //     // 首先全部的状态设为了false
        //     this.setCarItemStatus(this.carList)
        //     // 把当前的车辆状态重新设置一次
        //     if (status) {
        //       node.data.isPlay = true
        //     }
        //   }
        //   // 更改状态
        //   node.data.isPlay = !node.data.isPlay

        //   //通过勾选的节点长度判断视频窗口
        //   // if (this.playCarList.length > this.videoViewNum) {
        //   //   this.$message.error('视频个数不能超过' + this.videoViewNum + '个')
        //   //   //return
        //   // }
        //   //不能超过视频最大个数
        //   if (this.playCarList.length === this.videoViewNum) {
        //     this.$message.error('视频个数不能超过' + this.videoViewNum + '个')
        //     node.data.isPlay = false
        //   }
        //   // 调用递归函数 获取播放状态的车辆列表
        //   this.playCarList = this.getTreePropData(this.carList)
        // }
      },
      // 点击报警信息
      async showTable () {
        this.showWarning = !this.showWarning
        if (!this.showWarning) return
        await this.$search(this.table)
      },
      // 点击tabs
      clickTabs (i) {
        this.activeIndex = i
        this.activeIndex === 1 ? this.isRadio = true : this.isRadio = false
        this.playCarList = []
        this.setCarItemStatus(this.carList)
      },
      // 点击上线下线
      async online (node) {
        try {
          if (node.data.deviceStatus === '设备待机') {
            await this.$api.OnLine({
              deviceId: node.data.device
            })
            node.data.deviceStatus = '设备临时运行'
          } else {
            await this.$api.OffLine({
              deviceId: node.data.device
            })
            node.data.deviceStatus = '设备待机'
          }
        } catch (e) {
          console.log(e)
        }
      },
      // tree筛选条件
      filterNode (value, data) {
        /**
         * true  显示
         * false 隐藏
         */
        if (!value) return true
        // 只能筛选子子节点
        if (data.children) return false
        else {
          return data.id.indexOf(value) !== -1 || data.deviceName.indexOf(value) !== -1 || data.deviceStatus && data.deviceStatus.indexOf(value) !== -1
        }
      },
      /**
       * 区域查车反选中设备
       */
      selectionAllNodes (val) {
        console.log('执行选中操作!')
        this.$refs.tree.setCheckedKeys(val)
      },
      // handleNodeClick(data) {
      //   console.log(data[0].device);
      // },
      // 选择车辆
      checkClick (e) {
        console.log(e)
        this.newDeviceID = e.device
        this.checkCarItem = []
        // 单选
        // if (this.isRadio) {
        //   this.$refs.tree.setCheckedKeys([data.id])
        // }
        this.checkCarItem = this.$refs.tree.getCheckedNodes(true, false)
        // console.log(JSON.stringify(this.checkCarItem).indexOf(this.newDeviceID) !== -1)
      },
      // 递归函数=>给车辆列表默认播放状态
      setCarItemStatus (data, i = false) {
        if (!data) return
        data.forEach(e => {
          !e.children ? e.isPlay = i : this.setCarItemStatus(e.children)
        })
      },
      // 递归函数=>获取播放状态得车辆列表
      getTreePropData (list, childName = 'children', propName = 'isPlay') {
        if (!list) return
        const arr = []
        const getChild = (childrenList) => {
          if (!childrenList) return
          for (let i = 0; i < childrenList.length; i++) {
            childrenList[i][propName] ? arr.push(childrenList[i]) : getChild(childrenList[i][childName])
          }
        }
        getChild(list)
        return arr
      },
      async reset () {
        this.table.query.queryContent = ''
        this.table.query.alarmIdentifierList = []
        await this.$search(this.table)
      },
      jump () {
        this.$router.push({
          path: '/safety-center/alarm-management',
          query: {
            alarmIdentifierList: JSON.stringify(this.table.searchData[0].flat)
          }
        })
      },
      goDetail (val) {
        this.$router.push({
          path: '/safety-center/alarm-management/alarmDisposeDetails',
          query: {
            deviceId: val.deviceId,
            alarmStartTime: val.alarmStartTime,
            alarmType: val.alarmType,
            tenantId: val.tenantId,
            alarmName: val.alarmIdentifier
          }
        })
      },
      async goDealStatus (val) {
        if (val.dealStatus !== '人工处理') return
        this.detailsDialogVisible = true
        let detailsData = await this.$api.getAlarmEvidence({
          alarmName: val.alarmName,
          alarmType: val.alarmType,
          deviceId: val.deviceId,
          alarmStartTime: val.alarmStartTime,
          tenantId: val.tenantId
        })
        this.basicTitleData.dealName = detailsData.dealUserName
        this.basicTitleData.dealTime = this.$dayjs(detailsData.dealTime).format('YYYY-MM-DD HH:mm:ss')
        this.basicTitleData.dealType = detailsData.dealType
        this.basicTitleData.dealContent = detailsData.dealContent
      },
      async goPreview (val) {
        if (val.attache === 0) return
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        this.playerOptions.sources = videoData[0].sources
        this.videoUrl = this.playerOptions.sources[0].src
      },
      chg: function (ind, val) {
        this.url = this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem (val) {
        this.videoIdx = val
      }
    },
    async mounted () {
      // 获取后台数据的车辆列表
      const oldCarList = await this.$api.getRealTimeCarInfoList({})
      // 调用递归方法
      this.setCarItemStatus(oldCarList)
      // 深度克隆，不直接引用后台的值
      this.carList = this.$_.cloneDeep(oldCarList)
      // 调用递归, 获取所有公司下的车辆
      // console.log(this.$utils.getTreeAllData(this.carList))
      const num = await this.$api.GetTodayAiAlarmCount({})
      this.num = num.totalum
    },
  }
</script>

<style lang='sass' scoped>
  .box
    .content-box
      .box-car,
      .box-right
        display: inline-block
        height: 100%
        padding: 0
        vertical-align: top

      .box-car
        width: 318px

        &-top
          padding: 19px 12px
          border-bottom: 1px solid $grayShallow3

          .el-select
            width: 98px

          .el-input
            width: 112px
            margin: 0 10px

          /deep/ .el-input--suffix .el-input__inner
            padding-right: 18px

        &-tree
          margin: 10px 0 10px 5px
          height: calc(100% - 71px - 20px)
          overflow-y: auto

          .custom-tree-node
            flex: 1
            display: flex
            align-items: center

            .slot-icon
              margin-left: 5px

              .online,
              span > i
                color: $-color-primary-2

              .play-icon-active
                color: #05C517 !important

      .box-right
        width: calc(100% - 318px - 8px)
        margin-left: 8px
        position: relative

        .main-top
          display: flex
          align-items: center
          justify-content: space-between
          margin: 19px 0

          .top-tabs
            .item-active
              color: $-color-primary-2

            &-item
              display: inline-block
              font-size: $fontX
              color: #999999
              padding: 0 14px
              position: relative

              &::after
                content: ' '
                position: absolute
                top: 0
                right: 0
                width: 1px
                height: $fontX
                background-color: $grayShallow3

              &:last-child
                &::after
                  display: none

          .top-warning
            margin-right: 14px
            font-size: $fontX
            color: $-color-error

        .main-content
          height: calc(100% - 19px - 19px - 19px)
          padding: 0 4px 4px

        .main-table
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          border-radius: 4px
          padding: 12px
          background-color: $-color-white
          z-index: 2

    .attachment-main
      width: 100%

      .right-img
        width: 20%
        height: 520px
        display: inline-block
        vertical-align: top
        margin-left: 2%
        overflow-y: auto

        .images
          width: 100%

      .left-img
        width: 78%
        height: 520px
        display: inline-block

        .images
          width: 100%
          height: 100%

      .class
        display: block

      .show
        display: none
</style>
